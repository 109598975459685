import React from 'react'
import Logo from './Logo'

export default function OverlayContent({
  wide = false,
  withLogo = false,
  className,
  ...props
}) {
  return (
    <div
      className={[
        'w-full mx-auto bg-white rounded-tl-lg rounded-tr-lg md:rounded-bl-lg md:rounded-br-lg py-25 px-18 overflow-hidden',
        className,
        wide ? 'md:w-[950px]' : 'md:w-639'
      ]
        .join(' ')
        .trim()}
    >
      {withLogo && (
        <div className="mb-25">
          <Logo />
        </div>
      )}
      {props.children}
    </div>
  )
}
