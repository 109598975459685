import React from 'react'

export default function Logo(props) {
  return (
    <svg
      className="mx-auto w-full max-w-[200px]"
      {...props}
      viewBox="0 0 201 50"
      fill="none"
    >
      <path
        d="M27.0509 44.1075L21.7128 26.0361L29.4784 24.6659L31.5332 33.3583L32.0681 29.9971L32.8365 26.1971L38.7216 24.8926L41.011 33.0274L42.4737 25.3111L50.2394 23.9409L46.1749 42.3406L38.9734 43.6911L36.3943 36.6938L34.3276 42.7544L27.0509 44.1075Z"
        fill="#38403F"
      />
      <path
        d="M52.16 24.253L52.4947 18.4538L61.2006 17.0508L60.8659 22.85L52.16 24.253ZM53.476 43.8798L53.0578 31.096L50.5289 31.7338L50.9963 25.7086L60.9434 24.2622L61.5802 42.4978L53.476 43.8798Z"
        fill="#38403F"
      />
      <path
        d="M62.8909 42.815L62.7364 38.3907L69.226 29.5925L62.7015 30.9193L62.4798 24.5715L78.2345 22.8647L78.3997 27.5967L72.1886 35.2092L78.9209 34.9741L79.1345 41.0911L62.8909 42.815Z"
        fill="#38403F"
      />
      <path
        d="M80.3918 41.9036L80.2373 37.4794L86.7269 28.6811L80.2024 30.008L79.9807 23.6601L95.7354 21.9534L95.9006 26.6854L89.6896 34.2978L96.4218 34.0628L96.6354 40.1798L80.3918 41.9036Z"
        fill="#38403F"
      />
      <path
        d="M98.0986 42.4435L97.2334 17.6677L111.092 16.0848C113.549 15.999 115.463 16.5356 116.834 17.6946C118.205 18.8537 118.929 20.536 119.006 22.7417C119.05 23.9985 118.862 25.0708 118.441 25.9585C118.045 26.8455 117.493 27.5581 116.783 28.0964C117.801 28.4974 118.612 29.0726 119.216 29.8219C119.819 30.5455 120.146 31.6511 120.198 33.1387C120.279 35.4726 119.616 37.319 118.207 38.6779C116.799 40.0367 114.79 40.7616 112.183 40.8527L98.0986 42.4435ZM105.253 26.5449L109.249 25.8558C110.703 25.805 111.407 25.1128 111.361 23.7791C111.338 23.1379 111.133 22.6572 110.745 22.3369C110.382 21.9901 109.799 21.8307 108.997 21.8587L105.114 22.5438L105.253 26.5449ZM105.583 35.9704L109.729 35.2761C111.258 35.2227 111.998 34.4779 111.948 33.0416C111.901 31.7079 111.088 31.0687 109.508 31.1238L105.438 31.8155L105.583 35.9704Z"
        fill="#38403F"
      />
      <path
        d="M121.463 20.6363L121.797 14.837L130.503 13.434L130.169 19.2332L121.463 20.6363ZM122.779 40.2631L122.36 27.4792L119.832 28.1171L120.299 22.0918L130.246 20.6454L130.883 38.8811L122.779 40.2631Z"
        fill="#38403F"
      />
      <path
        d="M141.447 38.881C138.889 38.9703 137.024 38.3934 135.851 37.1505C134.677 35.8818 134.051 34.1446 133.974 31.9389L133.786 26.5529L131.567 26.6304L131.876 21.7L133.575 20.5128L133.985 16.868L141.471 14.7675L141.67 19.6806L145.016 18.9855L145.001 25.0334L141.881 25.7207L142.043 31.1564C142.086 32.3875 142.784 32.9794 144.138 32.9321C144.891 32.9059 145.647 32.2015 146.39 31.9188L146.567 37.7777C145.851 38.1109 145.081 38.3689 144.259 38.5516C143.437 38.7344 142.5 38.8442 141.447 38.881Z"
        fill="#38403F"
      />
      <path
        d="M1.70164 48.2965L0.786675 19.4001L9.85724 17.9199L10.1199 28.9801L15.2865 21.3763L24.8676 19.2464L17.0328 31.9066L27.0797 46.2154L18.097 47.726L11.4705 38.3729L10.4962 40.5979L10.7807 46.2175L1.70164 48.2965Z"
        fill="#38403F"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M175.271 47.7274L175.21 47.7253C175.22 47.7256 175.23 47.726 175.24 47.7263C175.251 47.7267 175.261 47.727 175.271 47.7274ZM199.488 25.1149L176.059 24.2967L176.877 0.86719C189.817 1.31906 199.94 12.1751 199.488 25.1149ZM152.629 23.4786C152.629 23.4896 152.628 23.5006 152.628 23.5117L152.629 23.4786L152.629 23.4786Z"
        fill="#F8B246"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M199.488 26.521C199.488 26.521 199.488 26.5211 199.488 26.5211C199.036 39.4609 188.18 49.5843 175.24 49.1325L176.058 25.7029L152.629 24.8847C152.629 24.8742 152.629 24.8637 152.63 24.8532L152.629 24.8847L199.488 26.521Z"
        fill="#CE477C"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M173.807 47.7254L173.861 47.7273C173.852 47.727 173.843 47.7266 173.834 47.7263C173.825 47.726 173.816 47.7257 173.807 47.7254ZM151.223 23.4786L174.653 24.2967L175.471 0.867185C162.531 0.415317 151.675 10.5388 151.223 23.4786ZM198.082 25.1149C198.082 25.1277 198.081 25.1404 198.081 25.1531L198.082 25.1149L198.082 25.1149Z"
        fill="#915EF6"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M175.386 2.27078L175.555 2.27667C175.527 2.27559 175.499 2.27456 175.47 2.27358C175.442 2.27259 175.414 2.27166 175.386 2.27078ZM151.223 24.8851L174.652 25.7033L173.834 49.1327C160.894 48.6809 150.771 37.8248 151.223 24.8851ZM198.082 26.5213C198.082 26.5088 198.083 26.4963 198.083 26.4838L198.082 26.5213L198.082 26.5213Z"
        fill="#2589E2"
      />
      <g filter="url(#filter0_d)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M179.159 27.2203C180.158 25.8389 180.264 23.93 179.28 22.4145C178.001 20.4463 175.37 19.8869 173.402 21.1651C171.433 22.4432 170.874 25.0749 172.152 27.043C173.136 28.5586 174.923 29.2388 176.592 28.8879L178.055 30.0781C178.808 30.6907 179.905 29.9787 179.651 29.0415L179.159 27.2203Z"
          fill="white"
        />
      </g>
      <defs>
        <filter
          id="filter0_d"
          x="166.467"
          y="20.479"
          width="18.4994"
          height="19.8276"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="5" />
          <feGaussianBlur stdDeviation="2.5" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  )
}
