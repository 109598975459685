import { useTrackingCode } from 'react-hubspot-tracking-code-hook'
import Script from 'next/script'

export default function HubSpot({ page }) {
  const { setPathPageView, setTrackPageView } = useTrackingCode()
  const _hsq = typeof window !== 'undefined' && window._hsq ? window._hsq : []

  setPathPageView(page)

  return (
    <div>
      <Script
        id="hs-script-loader"
        type="text/javascript"
        src="https://js.hs-scripts.com/20121422.js"
        strategy="lazyOnload"
      />
    </div>
  )
}
