import '../css/main.css'
import { AnimateSharedLayout } from 'framer-motion'
import { SignUpProvider } from '../context/SignUpContext'
import { AuthProvider } from '../context/AuthContext'
import { CookiesProvider } from 'react-cookie'
import { QuizProvider } from '../context/QuizContext'
import { QuizBuilderProvider } from '../context/QuizBuilderContext'
import { VideoStreamingProvider } from '../context/VideoStreamingContext'
import { QueryClientProvider, QueryClient } from 'react-query'
import React, { useEffect } from 'react'
import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import DateAdapter from '@mui/lab/AdapterDayjs'
import { BackgroundMusicProvider } from '~/context/BackgroundMusicContext'
import ReactGA from 'react-ga4'
import Maintenance from './maintenance-page'

Bugsnag.start({
  apiKey: process.env.bugsnag_key,
  plugins: [new BugsnagPluginReact()]
})

const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React)

function MyApp({ Component, pageProps }) {
  const queryClient = new QueryClient()
  const TRACKING_ID = 'G-FRKYELQPS1'
  useEffect(() => {
    ReactGA.initialize(TRACKING_ID)
  }, [])

  //remove this line to enable maintenance page
  //return <Maintenance />

  return (
    <ErrorBoundary>
      <CookiesProvider>
        <AuthProvider>
          <SignUpProvider>
            <QuizBuilderProvider>
              <QuizProvider>
                <AnimateSharedLayout>
                  <QueryClientProvider client={queryClient}>
                    <VideoStreamingProvider>
                      <BackgroundMusicProvider>
                        <LocalizationProvider dateAdapter={DateAdapter}>
                          <Component {...pageProps} />
                        </LocalizationProvider>
                      </BackgroundMusicProvider>
                    </VideoStreamingProvider>
                  </QueryClientProvider>
                </AnimateSharedLayout>
              </QuizProvider>
            </QuizBuilderProvider>
          </SignUpProvider>
        </AuthProvider>
      </CookiesProvider>
    </ErrorBoundary>
  )
}

export default MyApp
