import { useState, createContext, useReducer, useEffect } from 'react'
import { useSessionStorage } from 'react-use'
const QuizContext = createContext()

const initialState = {
  quizUuid: '',
  quizPin: '',
  quizStatus: false,
  questionStatus: false,
  leaderboardData: null,
  playQuizData: false,
  questionNumber: 0,
  endRefresh: false,
  hasCompanyTerms: false,
  companyTerms: false,
  hasCustomTerms: false
}

const playReducer = (state, action) => {
  console.log('playReducer', action)
  switch (action.type) {
    case 'SET_QUIZ_UUID':
      return {
        ...state,
        quizUuid: action.payload
      }
    case 'SET_QUIZ_STATUS':
      return {
        ...state,
        quizStatus: action.payload
      }
    case 'SET_QUESTION_STATUS':
      return {
        ...state,
        questionStatus: action.payload
      }
    case 'SET_LEADERBOARD_DATA':
      return {
        ...state,
        leaderboardData: action.payload
      }
    case 'SET_PLAY_QUIZ_DATA':
      return {
        ...initialState,
        playQuizData: action.payload
      }
    case 'SET_HAS_COMPANY_TERMS':
      return {
        ...state,
        hasCompanyTerms: action.payload
      }
    case 'SET_HAS_CUSTOM_TERMS':
      return {
        ...state,
        hasCustomTerms: action.payload
      }

    case 'SET_QUESTION_NUMBER':
      return {
        ...state,
        questionNumber: action.payload
      }
    case 'INCREMENT_QUESTION_NUMBER':
      return {
        ...state,
        questionNumber: state.questionNumber + 1
      }
    case 'SET_QUIZ_PIN':
      return {
        ...state,
        quizPin: action.payload
      }
    case 'SET_END_REFRESH':
      return {
        ...state,
        endRefresh: true
      }
    case 'RESET':
      return initialState

    default:
      return state
  }
}

export const QuizProvider = ({ children }) => {
  const [quizData, setQuizData] = useState({
    runningTotal: 0,
    userCorrect: null
  })

  const [playData, setPlayData] = useSessionStorage('play-data', initialState)

  const [playState, playDispatch] = useReducer(playReducer, playData)

  useEffect(() => {
    setPlayData(playState)
  }, [playState])

  return (
    <QuizContext.Provider
      value={{ quizData, setQuizData, playState, playDispatch, playData }}
    >
      {children}
    </QuizContext.Provider>
  )
}

export default QuizContext
