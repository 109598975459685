const Charlie = ({ as: Node = 'h3', children, className = '', ...props }) => (
  <Node
    className={[`text-charlie-fluid font-bold`, className].join(' ').trim()}
    {...props}
  >
    {children}
  </Node>
)

export default Charlie
