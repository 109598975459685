const Bravo = ({ as: Node = 'h2', children, className = '', ...props }) => (
  <Node
    className={[`text-bravo-fluid font-bold`, className].join(' ').trim()}
    {...props}
  >
    {children}
  </Node>
)

export default Bravo
