import { useState, createContext } from 'react'

const BackgroundMusicContext = createContext()

export const BackgroundMusicProvider = ({ children }) => {
  const [isMusicMuted, setIsMusicMuted] = useState(false)

  return (
    <BackgroundMusicContext.Provider value={{ isMusicMuted, setIsMusicMuted }}>
      {children}
    </BackgroundMusicContext.Provider>
  )
}

export default BackgroundMusicContext
