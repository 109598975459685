const Body = ({
  as: Node = 'span',
  children,
  large = false,
  small = false,
  xs = false,
  className = '',
  ...props
}) => (
  <Node
    className={[
      `
        block
        ${xs ? 'text-xs-fluid' : ''}
        ${small ? 'text-sm-fluid' : ''}
        ${large ? 'text-lg-fluid' : ''}
        ${!large && !small ? 'text-body-fluid' : ''}
      `,
      className
    ]
      .join(' ')
      .trim()}
    {...props}
  >
    {children}
  </Node>
)

export default Body
