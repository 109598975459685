import { useState, useReducer, createContext } from 'react'

const SignUpContext = createContext()

const initialStepContent = [
  {
    id: 'email',
    title: 'Create your free account',
    copy: 'Quiz anyone, on anything, from anywhere',
    backgroundColor: 'bg-blue-500',
    image: '/images/register/step1.png',
    skip: false
  },
  {
    id: 'player-host',
    title: 'Tell us about yourself',
    copy: 'Over 1000 quizzes in your pocket',
    backgroundColor: 'bg-hibiscus-500',
    image: '/images/register/step2.png',
    skip: false
  },
  {
    id: 'host-type',
    title: 'Where will you be hosting quizzes?',
    copy: 'Create quizzes in minutes from a database of 30,000+ questions',
    backgroundColor: 'bg-purple-500',
    image: '/images/register/step3.png',
    skip: false
  },
  {
    id: 'user-details',
    title: 'Create your free account',
    copy: 'Build, host and play interactive quizzes every day!',
    backgroundColor: 'bg-orange-500',
    image: '/images/register/step4.png',
    skip: false
  }
]

const playerStepEmail = {
  id: 'email',
  title: 'Create your free account',
  copy: 'Online quizzing direct to your phone',
  backgroundColor: 'bg-blue-500',
  image: '/images/register/step1.png',
  skip: false
}
const playerStepUserDetails = {
  id: 'user-details',
  title: 'Create your free account',
  copy: 'Play quizzes and compete with friends',
  backgroundColor: 'bg-orange-500',
  image: '/images/register/step4player.png',
  skip: false
}
const playerStepContent = [
  {
    id: 'email',
    title: 'Create your free account',
    copy: 'Online quizzing direct to your phone',
    backgroundColor: 'bg-blue-500',
    image: '/images/register/step1.png',
    skip: false
  },
  {
    id: 'player-host',
    skip: true
  },
  {
    id: 'host-type',
    skip: true
  },
  {
    id: 'user-details',
    title: 'Create your free account',
    copy: 'Play quizzes and compete with friends',
    backgroundColor: 'bg-orange-500',
    image: '/images/register/step2.png',
    skip: false
  }
]
const stepsData = {
  stepCount: 0,
  stepContent: initialStepContent,
  host_types: []
}

const stepReducer = (state, action) => {
  console.log('state', state)
  switch (action.type) {
    case 'NEXT_STEP':
      const nextStepIndex = state.stepContent.findIndex(
        (step, index) => index > state.stepCount && !step.skip
      )
      return {
        ...state,
        stepCount: nextStepIndex !== -1 ? nextStepIndex : state.stepCount
      }

    case 'PREV_STEP':
      // Reverse the array of steps and find the index of the next step without skip
      const prevStepIndex = [...state.stepContent]
        .reverse()
        .findIndex(
          (step, index) =>
            index > state.stepContent.length - 1 - state.stepCount && !step.skip
        )

      // Calculate the index of the previous step from the end of the array
      const prevStepFromEndIndex =
        prevStepIndex !== -1
          ? state.stepContent.length - 1 - prevStepIndex
          : state.stepCount

      // Return state with the updated stepCount
      return {
        ...state,
        stepCount:
          prevStepFromEndIndex >= 0 ? prevStepFromEndIndex : state.stepCount
      }
    case 'PLAYER_STEPS':
      return {
        ...state,
        stepContent: state.stepContent.map((step) => {
          if (step.id === 'email') {
            return playerStepEmail
          }
          if (step.id === 'user-details') {
            return playerStepUserDetails
          }
          if (step.id === 'host-type' || step.id === 'player-host') {
            return { ...step, skip: true }
          }
          return step
        })
      }

    case 'SKIP_HOST_STEPS':
      return {
        ...state,
        stepContent: state.stepContent.map((step) => {
          if (step.id === 'host-type' || step.id === 'player-host') {
            return { ...step, skip: true }
          }
          return step
        })
      }
    case 'SKIP_EMAIL_STEP':
      return {
        ...state,
        stepContent: state.stepContent.map((step) => {
          if (step.id === 'email') {
            return { ...step, skip: true }
          }
          return step
        })
      }
    case 'SKIP_PLAYER_STEPS':
      return {
        ...state,
        stepContent: state.stepContent.map((step) => {
          if (step.id === 'user-details') {
            return playerStepUserDetails
          }
          if (step.id === 'host-type') {
            return { ...step, skip: true }
          }
          return step
        })
      }
    case 'SET_HOST_STEPS':
      return {
        ...state,
        stepContent: state.stepContent.map((step) => {
          return { ...step, skip: false }
        })
      }
    case 'SET_HOST_TYPES':
      return {
        ...state,
        host_types: action.payload
      }
    default:
      return state
  }
}

export const SignUpProvider = ({ children }) => {
  const [userDetails, setUserDetails] = useState({
    device_name: 'website',
    is_host: false,
    host_type: '',
    email: '',
    name: '',
    password: '',
    password_confirmation: '',
    plan: '',
    marketing: false,
    terms: false
  })

  const [steps, stepsDispatch] = useReducer(stepReducer, stepsData)

  return (
    <SignUpContext.Provider
      value={{ userDetails, setUserDetails, steps, stepsDispatch }}
    >
      {children}
    </SignUpContext.Provider>
  )
}

export default SignUpContext
