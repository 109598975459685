import { useAsync } from 'react-async-hook'

import axios from 'axios'

const fetchSubscriptionStatus = async (token) =>
  await axios.get(`${process.env.base_api}/billing/subscription-status`, {
    headers: {
      Accept: 'application/json',
      'X-Requested-With': 'XMLHttpRequest',
      Authorization: `Bearer ${token}`
    }
  })

export default function useSubscriptionStatus(token) {
  const data = useAsync(fetchSubscriptionStatus, [token])
  return data?.result?.data ? data.result.data : false
}
