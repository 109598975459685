import { useState, createContext } from 'react'

const VideoStreamingContext = createContext()

export const VideoStreamingProvider = ({ children }) => {
  const [isStreaming, setIsStreaming] = useState(false)
  const [isFeedPublished, setIsFeedPublished] = useState(false)
  const [isMuted, setIsMuted] = useState(true)
  const [streamId, setStreamId] = useState(null)

  return (
    <VideoStreamingContext.Provider
      value={{
        isStreaming,
        setIsStreaming,
        isMuted,
        setIsMuted,
        isFeedPublished,
        setIsFeedPublished,
        streamId,
        setStreamId
      }}
    >
      {children}
    </VideoStreamingContext.Provider>
  )
}

export default VideoStreamingContext
