import { useRouter } from 'next/router'
import { useEffect } from 'react'
import Head from 'next/head'
import Body from '~/components/typography/Body'
import Bravo from '~/components/typography/Bravo'
import Charlie from '~/components/typography/Charlie'
import OverlayContent from '~/components/OverlayContent'
import HubSpot from '~/components/HubSpot'
import Logo from '~/components/nav/Logo'

export default function MaintenancePage() {
  const router = useRouter()

  useEffect(() => {}, [])

  return (
    <>
      <Head>
        <title>Under Maintenance | KwizzBit</title>
      </Head>
      <div className="bg-blue-500 h-screen min-h-[900px] pb-80 w-full">
        <div className="container flex-col flex items-center justify-center h-full">
          <Logo className="w-[300px] mb-50" />
          <div className="flex flex-col items-center justify-center ">
            <Bravo className="text-white mb-20 font-bold">
              We're updating KwizzBit
            </Bravo>
            <Body className="text-white text-center max-w-screen-md mb-10">
              We're currently updating our platform to bring you new features
              and improvements. We'll be back shortly.
            </Body>
            <Body className="text-white text-center max-w-screen-md">
              Contact us on{' '}
              <a className="underline" href="tel:+447808956145">
                07808956145
              </a>{' '}
              or email us at{' '}
              <a className="underline" href="mailto:info@kwizzbit.com">
                info@kwizzbit.com
              </a>{' '}
              if you have any questions.
            </Body>
            <Charlie className="text-white mt-20">
              Thank you for your patience
            </Charlie>
          </div>
        </div>
      </div>
      <HubSpot page={'maintenance/'} />
    </>
  )
}
